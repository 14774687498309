import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const ChatAudioFile = () => {
	const [messages, setMessages] = useState([]);
	const [input, setInput] = useState("");
	const [recognition, setRecognition] = useState(null);
	const [isRecording, setIsRecording] = useState(false);
	const [isTyping, setIsTyping] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [playingMessageId, setPlayingMessageId] = useState(null);
	const [voices, setVoices] = useState([]);
	const [voiceURI, setVoiceURI] = useState("en-US");
	const [pitch, setPitch] = useState(1);
	const [rate, setRate] = useState(1.1);
	const [volume, setVolume] = useState(1);
	// const [isRecording, setIsRecording] = useState(false);
	const [recordingTime, setRecordingTime] = useState(0);
	const [isLoading, setisLoading] = useState(false);

	const [isPlaying, setIsPlaying] = useState(false);
	const [audioInstance, setAudioInstance] = useState(null);
	const chatBoxRef = useRef(null);

	useEffect(() => {
		const SpeechRecognition =
			window.SpeechRecognition || window.webkitSpeechRecognition;
		if (SpeechRecognition) {
			const recognitionInstance = new SpeechRecognition();
			recognitionInstance.interimResults = true;
			recognitionInstance.lang = "en-US";

			recognitionInstance.onresult = (event) => {
				const transcript = event.results[event.resultIndex][0].transcript;
				setInput(transcript);
			};

			setRecognition(recognitionInstance);
		} else {
			alert("Speech recognition is not supported in this browser.");
		}
	}, []);

	useEffect(() => {
		setMessages([
			{
				text: "Welcome to Nexuschat! How can I help you today?",
				sender: "bot",
			},
			
		]);
	}, []);

	useEffect(() => {
		if (chatBoxRef.current) {
			chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
		}
	}, [messages]);

	const handleSendMessage = async () => {
		if (input.trim()) {
			const modifiedInput = input.replace(/symphony/gi, "symphony");

			const userMessage = { text: modifiedInput, sender: "user" };
			setMessages((prev) => [...prev, userMessage]);

			setIsSending(true);
			setIsTyping(true);
			setInput("");
			try {
				const messageId = uuidv4();
				setMessages((prev) => [
					...prev,
					{ id: messageId, text: "...", sender: "bot" },
				]);

				const botResponse = await chatWithBot(input);
				console.log("botResponsebotResponse", botResponse);
				const cleanedString = botResponse
					?.replace(/^"|"$/g, "")
					.replace(/\n/g, "<br>")
					.replace(/ \n /g, "<br>")
					.replace(/\n\n/g, "<br> <br>")
					.replace(/\\n/g, "<br>")
					.replace(/ \n/g, "<br>")
					.replace(/ \n /g, "<br>")
					.replace(/ \n \n/g, "<br> <br>")
					.replace(/\n \n /g, "<br> <br>")
					.replace(/ ?\n\n/g, "<br> <br>")
					.replace(/.\n \n /g, "<br> <br>")
					.replace(/```(.*?)```/gs, "<code>$1</code>")
					.replace(/\`(.+?)\`/g, "<code>$1</code>");

				simulateBotResponse(cleanedString, messageId);
			} catch (error) {
				console.error("Error in handleSendMessage:", error);
				setMessages((prev) => [
					...prev,
					{ text: "Failed communicating with the bot.", sender: "bot" },
				]);
			} finally {
				setInput("");
			}
		}
	};

	const simulateBotResponse = (response, messageId) => {
		let accumulatedResponse = "";
		let currentIndex = 0;

		const intervalId = setInterval(() => {
			accumulatedResponse += response[currentIndex];
			currentIndex += 1;

			setMessages((prev) => {
				return prev.map((message) => {
					if (message.id === messageId) {
						return { ...message, text: accumulatedResponse };
					}
					return message;
				});
			});

			if (currentIndex >= response.length) {
				clearInterval(intervalId);
				setIsSending(false);
				setIsTyping(false);
			}
		}, 30);
	};

	const startListening = () => {
		if (recognition) {
			setIsRecording(true);
			recognition.start();
		}
	};

	const stopListening = () => {
		if (recognition) {
			recognition.stop();
			setIsRecording(false);
			setRecordingTime(0);

			handleSendMessage();
		}
	};

	const cancelListening = () => {
		if (recognition) {
			recognition.stop();
			setIsRecording(false);
			setRecordingTime(0);
			setInput("");

			// handleSendMessage();
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleSendMessage();
		}
	};

	// const API_KEY = "";
	// const ENDPOINT_ID = "";

	// const chatWithBot = async (query) => {
	// 	let jobId;

	// 	const payload = {
	// 		input: {
	// 			prompt: query,
	// 		},
	// 	};

	// 	try {
	// 		const startResponse = await fetch(`${ENDPOINT_ID}/run`, {
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 				Authorization: `Bearer ${API_KEY}`,
	// 			},
	// 			body: JSON.stringify(payload),
	// 		});

	// 		if (!startResponse.ok) {
	// 			throw new Error(`Error: ${startResponse.statusText}`);
	// 		}

	// 		const { id } = await startResponse.json();
	// 		if (!id) throw new Error("Failed to retrieve job ID.");

	// 		const pollStatus = async (id) => {
	// 			const statusResponse = await fetch(`${ENDPOINT_ID}/status/${id}`, {
	// 				method: "GET",
	// 				headers: {
	// 					Authorization: `Bearer ${API_KEY}`,
	// 				},
	// 			});

	// 			if (!statusResponse.ok) {
	// 				throw new Error(`Status API Error: ${statusResponse.statusText}`);
	// 			}

	// 			return await statusResponse.json();
	// 		};

	// 		let isPolling = true;
	// 		while (isPolling) {
	// 			const statusData = await pollStatus(id);

	// 			console.log(`Polling status for ID ${id}: ${statusData.status}`);

	// 			if (
	// 				statusData.status === "COMPLETED" ||
	// 				statusData.status === "FAILED"
	// 			) {
	// 				isPolling = false;
	// 				if (statusData.status === "FAILED") {
	// 					throw new Error("Process failed.");
	// 				}

	// 				if (statusData.status === "TIMED_OUT") {
	// 					throw new Error("Process failed.");
	// 				}
	// 				return (
	// 					JSON.parse(statusData?.output?.body)?.reply || "No reply received."
	// 				);
	// 			}
	// 			const retryAfter = statusData.retryAfter || 5000;
	// 			await new Promise((resolve) => setTimeout(resolve, retryAfter));
	// 		}
	// 	} catch (error) {
	// 		console.error("Failed to communicate with bot:", error);
	// 		return "Failed communicating with bot.";
	// 	}
	// };

	const chatWithBot = async (query) => {
			 	try {
			 		const response = await fetch(
			 		
							`${process.env.REACT_APP_API_URL}/chat`,
			 			{
			 				method: "POST",
			 				headers: {
			 					"Content-Type": "application/json",
			 				},
			 				body: JSON.stringify({ query: query }),
			 			}
			 		);

			 		if (!response.ok) {
			 			throw new Error(`Error: ${response.statusText}`);
			 		}

			 		const reader = response.body.getReader();
			 		const decoder = new TextDecoder("utf-8");
			 		let result = "";

			 		while (true) {
			 			const { done, value } = await reader.read();
			 			if (done) break;
			 			result += decoder.decode(value, { stream: true });
			 		}

			 		return result || "No reply received.";
			 	} catch (error) {
			 		console.error("Failed to communicate with bot:", error);
			 		return "failed communicating with bot.";
			 	}
			 };
		

	useEffect(() => {
		const updateVoices = () => {
			const availableVoices = window.speechSynthesis.getVoices();
			setVoices(availableVoices);
			if (availableVoices.length > 0 && !voiceURI) {
				setVoiceURI(availableVoices[0].voiceURI);
			}
		};

		updateVoices();
		window.speechSynthesis.onvoiceschanged = updateVoices;
	}, [voiceURI]);

	const speakText = async (text, messageId) => {

		// if (isPlaying) {
		// 	audioInstance.pause();
		// 	setIsPlaying(false);
		// 	setPlayingMessageId(null);

		// 	return;
		// }

		setisLoading(true);
		setPlayingMessageId(messageId);
		setIsPlaying(true);

		try {
			setisLoading(true);
			setPlayingMessageId(messageId);
			const response = await axios.post(
				`${process.env.REACT_APP_DEEPGRAM_URL}=${process.env.REACT_APP_DEEPGRAM_MODEL}`,
				{ text: text },
				{
					headers: {
						Authorization: `Token ${process.env.REACT_APP_DEEPGRAM_API_KEY}`,
						"Content-Type": "application/json",
					},
					responseType: "arraybuffer",
				}
			);

			const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
			const audioUrl = URL.createObjectURL(audioBlob);
			const audio = new Audio(audioUrl);

			audio.play();
			setPlayingMessageId(messageId);
			setAudioInstance(audio);
			setIsPlaying(true);

			audio.onended = () => {
				setPlayingMessageId(null);
				setIsPlaying(false);
			};
		} catch (error) {
			console.error("Error with Deepgram TTS:", error);
			setPlayingMessageId(null);
		} finally {
			// setPlayingMessageId(null);
			setisLoading(false);
		}
	};

	const stopAudio = () => {
		if (audioInstance) {
			audioInstance.pause();
			audioInstance.currentTime = 0;
			setIsPlaying(false); // Update the state to reflect that audio is stopped
			setPlayingMessageId(null); // Clear the message ID
		} else {
			console.error("No audio to stop.");
		}
	};
	useEffect(() => {
		let timer;
		if (isRecording) {
			timer = setInterval(() => setRecordingTime((prev) => prev + 1), 1000);
		}
		return () => clearInterval(timer);
	}, [isRecording]);

	const formatTime = (timeInSeconds) => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = timeInSeconds % 60;
		return `${minutes.toString().padStart(2, "0")}:${seconds
			.toString()
			.padStart(2, "0")}`;
	};

	const WaitingMessageAnimation = () => (
		<div className="dot-pulse">
			<span></span>
			<span></span>
			<span></span>
		</div>
	);

	return (
		<div className="chat-container">
			<div
				className={isRecording ? "chat-box-recording" : "chat-box"}
				ref={chatBoxRef}
			>
				{messages.map((msg, index) => (
					<div className="msg" key={index}>
						<div className={`message ${msg.sender}`}>
							{msg.text === "..." ? (
								<WaitingMessageAnimation />
							) : (
								// msg.text
								<div
									id="formattedText"
									dangerouslySetInnerHTML={{ __html: msg.text }}
								/>
							)}
						</div>
						{msg.sender === "bot" && msg.text.length > 40 && !isTyping && (
							<button
								onClick={() => {
									if (isPlaying) {
										stopAudio(); // Stop the audio if it's currently playing
									} else {
										speakText(msg.text, index); // Start speaking the message if not already playing
									}
								}}
								className="speakbtn"
							>
								{playingMessageId === index ? (
									isLoading ? (
										<i className="fa-solid fa-spinner"></i>
									) : isPlaying ? (
										<i className={`fa-solid fa-circle-stop`}></i>
									) : (
										<i className={`fa-solid fa-volume-high`}></i>
									)
								) : (
									<i className={`fa-solid fa-volume-high`}></i>
								)}
							</button>
						)}
					</div>
				))}
			</div>

			<div className="input-container">
				<input
					type="text"
					value={input}
					onChange={(e) => setInput(e.target.value)}
					onKeyPress={handleKeyPress}
					placeholder="Type your message or speak..."
					disabled={isTyping}
				/>
				{!isRecording && (
					<button
						className="send-btn"
						onClick={handleSendMessage}
						disabled={isTyping || isSending}
					>
						<i className="fa-solid fa-arrow-up-long"></i>
					</button>
				)}

				<button
					onClick={isRecording ? cancelListening : startListening}
					disabled={isTyping || isRecording}
					className="mic-button"
				>
					<i className={`fas fa-microphone${isRecording ? "-slash" : ""}`} />
				</button>
			</div>
			{isRecording && (
				<div className="recording-overlay">
					<p className="timer">{formatTime(recordingTime)}</p>
					<div className="stop-button-wrapper">
						<button onClick={stopListening} className="stop-recording-button">
							<div className="circle-icon">
								<i className="fas fa-square"></i>
							</div>
							<p>Tap to stop recording</p>
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChatAudioFile;
